const ENTER_NUMBER_STEP = 'enterNumber';
const ENTER_OTP_STEP = 'enterOtp';
const ENTER_PASSWORD_STEP = 'enterPassword';
const ADD_NUMBER_STEP = 'addNumber';
const VERIFY_OTP_STEP = 'verifyOtp';
const CONFIRM_AGE = 'confirmAge';
const ACCOUNT_TYPE_LOGIN = 'login';
const ACCOUNT_TYPE_SIGNUP = 'signup';
const LOGIN_TYPE_NUMBER = 'number';
const LOGIN_TYPE_EMAIL = 'email';
const ENTER_OTP_UNREGISTERED_USER_STEP = 'enterOtpUnRegisteredUser';
const UPDATE_PARTIAL_USER_DETAILS_STEP = 'updatePartialUserDetails';

export const US_COUNTRY_CODE = 'US';
export const ERROR_CODE = {
  GOOGLE_ACCOUNT_NOT_FOUND: 'E149',
  INVALID_OTP: 'E601',
  ACCOUNT_NOT_FOUND: 'E124',
  EMAIL_ALREADY_REGISTERED: 'E132',
  INVALID_EMAIL: 'E112',
  INVALID_PASSWORD: 'E133',
  PROFANE_CONTENT_USED: 'E164',
  PASSWORD_LENGTH_TOO_SMALL: 'E114',
  NAME_ERROR: ['E163', 'E160', 'E164', 'E161', 'E162', 'E126'],
  UN_IMG_SRC_NOT_FOUND: 'UN_IMG_SRC_NOT_FOUND',
  UN_IMG_WIDTH_NOT_FOUND: 'UN_IMG_WIDTH_NOT_FOUND',
  UN_IMG_HEIGHT_NOT_FOUND: 'UN_IMG_HEIGHT_NOT_FOUND',
  UN_IMG_INVALID_CDN: 'UN_IMG_INVALID_CDN',
  UN_IMG_LEGACY: 'UN_IMG_LEGACY',
  UN_IMG_WIDTH_HEIGHT_NOT_INTEGER: 'UN_IMG_WIDTH_HEIGHT_NOT_INTEGER'
};

export const TalkState = {
  SHOW: 0,
  NO_SHOW: 2,
  LOCAL_NO_SHOW: 3
};

export const US_PHONE_INFO = {
  code: 'US',
  name: 'United States',
  flagIconUrl: 'https://static.uacdn.net/thumbnail/country-flag-icons/us.png',
  phoneCode: '1',
  ageGroups: []
};

export const IND_PHONE_INFO = {
  code: 'IN',
  name: 'India',
  flagIconUrl: 'https://static.uacdn.net/thumbnail/country-flag-icons/in.png',
  phoneCode: '91'
};

const LOGIN_PROMOTION_MESSAGE = 'Over 8L learners preparing with Unacademy';

// Segment events
export const LOGIN_STARTED = 'Login - Started';
export const LOGIN_OTP_REQUESTED = 'Login - OTP Requested';
export const LOGIN_OTP_SUBMITTED = 'Login - OTP Submitted';
export const LOGIN_SUCCESSFUL = 'Login - Successful';
export const LOGIN_SELECT_AGE = 'Login - Age Group Selected';
export const LOGIN_ACCEPT_CONSENT = 'Login - Accepted Consent';
export const LOGIN_ACCOUNT_CREATION_FAILED = 'Login - Account Creation Failed';
export const LOGIN_SIGN_UP_FIELD_CLICKED = 'Login - Signup field clicked';
export const EXPERIMENT_LOGIN_OTP_SCREEN_VIEWED =
  'Experiment - OTP Screen Viewed';
export const LOGIN_MODEL_DISMISSED = 'Login - Signup Modal Dismissed';
export const LOGIN_SIDE_CURTAIN_DISMISSED =
  'Login - Signup Side curtain dismissed';
export const CONTINUE_WITH_EMAIL = 'Login - Continue with Email Clicked';
export const CONTINUE_WITH_PHONE = 'Login - Continue with Mobile Clicked';
export const OTP_INVALID = 'Login - OTP Invalid';
export const LOGIN_SIGNUP_CONTINUE = 'Login - Signup Continue';
export const LOGIN_SIGNUP_CONTINUE_FAILED = 'Login - Signup Continue Failed';
export const SIGNUP_SUCCESFUL = 'Signup - Successful';
// Login Methods
export const LOGIN_METHOD_PHONE_OTP = 'mobile_otp';
export const LOGIN_METHOD_EMAIL_OTP = 'email_otp';
export const LOGIN_METHOD_EMAIL_PHONE_OTP = 'email_phone_otp';

// Login sources
export const LOGIN_SOURCE_PAGE = 'page';
export const LOGIN_SOURCE_CARD = 'card';
export const LOGIN_SOURCE_TTU = 'ttu';
export const LOGIN_SOURCE_IFRAME = 'iframe';

// A/B experiments keys
export const SIGNUP_FLOW_OPTIMIZATIONS = 'Signup_flow_optimisations_web';

export const SEGMENT_SIGNUP_FIELD_NAMES = {
  PHONE_NUMBER: 'phone_number',
  EMAIL: 'Email',
  OTP: 'OTP',
  NAME: 'Name',
  STATE: 'State of residence'
};

const constants = {
  ENTER_NUMBER_STEP,
  ENTER_OTP_STEP,
  ENTER_PASSWORD_STEP,
  ADD_NUMBER_STEP,
  VERIFY_OTP_STEP,
  CONFIRM_AGE,
  ACCOUNT_TYPE_LOGIN,
  ACCOUNT_TYPE_SIGNUP,
  LOGIN_TYPE_NUMBER,
  LOGIN_TYPE_EMAIL,
  ERROR_CODE,
  SEGMENT_SIGNUP_FIELD_NAMES,
  LOGIN_PROMOTION_MESSAGE,
  ENTER_OTP_UNREGISTERED_USER_STEP,
  UPDATE_PARTIAL_USER_DETAILS_STEP
};

export const initialFieldsData = {
  name: { type: 'name', value: '' },
  email: { type: 'email', value: '' },
  state: { type: 'state', value: '' }
};

export const defaultCountry = {
  code: 'IN',
  name: 'India',
  flagIconUrl: 'https://static.uacdn.net/thumbnail/country-flag-icons/in.png',
  phoneCode: '91',
  ageGroups: []
};

export const LOGIN_MODAL_EXPERIMENT = 'signup_modal_experiment_web_v3';
export const LOGIN_MODAL_EXPERIMENT_ID = '241';
export const OTP_REGEX = /^\d{6}$/;

// signup_modal_experiment_web experiment is enabled for these routes
export const routesToBeInCluded = [
  '/',
  '/[authorID]',
  '/[authorID]/courses',
  '/batch/[slug]/[batchUID]',
  '/store/batch/[slug]/[batchUID]',
  '/class/[slug]/[uid]',
  '/ab/test1/class/[slug]/[uid]',
  '/ab/test2/class/[slug]/[uid]',
  '/course/[slug]/[courseUID]',
  '/goal/[goalSlug]/[goalUID]',
  '/lesson/[lessonSlug]/[lessonUID]',
  '/test-series/[slug]/[courseUID]',
  '/ab/home/pm-banner',
  '/explore',
  '/goal/[goalSlug]/[goalUID]/subscribe',
  '/goal/[goalSlug]/[goalUID]/subscriptions',
  '/deleteyouraccount'
];

export default constants;
